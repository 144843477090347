import React from "react";
import { isChrome, isFirefox, isIeEdge, getBrowser } from "@tightrope/lpscripts/browserdetect";
import Styles from "./css/gwlp.module.scss";
import Data from "./data/gwlp.json";
import CtaDisclosureNewtab from "@tightrope/ctadisclosurenewtab"

class gwlp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: "none",
      pageData: props.data,
      data: Data,
      isLoading: true
    };
  }

  showBrowsers(browsers) {
    let display = false;
    if (getBrowser() === "ie" && browsers.includes("ie")) {
      display = true;
    }
    if (getBrowser() === "firefox" && browsers.includes("firefox")) {
      display = true;
    }
    if (getBrowser() === "chrome" && browsers.includes("chrome")) {
      display = true;
    }
    if (getBrowser() === "edge" && browsers.includes("edge")) {
      display = true;
    }
    if (getBrowser() === "other" && browsers.includes("other")) {
      display = true;
    }
    if (display) this.setState({ display: "block" });
    else this.setState({ display: "none" });
  }

  componentDidMount() {
    if (this.props.browsers) {
      this.showBrowsers(this.props.browsers);
    } else {
      this.setState({ display: "block" });
    }
    if (this.props.data) {
      this.props.data.then((data) => {
        const instanceData = Object.assign(this.state.data, data);
        this.setState({ data: instanceData });
        this.state.isChrome = isChrome();
        this.state.isFirefox = isFirefox();
        this.state.isIeEdge = isIeEdge();

        if (isChrome()) {
          const browserData = Object.assign(this.state.data, this.state.data.chrome);
          this.setState({ data: browserData });
          document.querySelectorAll('.animCTA').forEach(p => {
                  p.innerText = this.state.data.button;
          });
        }
        if (isFirefox()) {
          const browserData = Object.assign(this.state.data, this.state.data.firefox);
          this.setState({ data: browserData });
        }
      });
    }

    window.addEventListener("load", () => {
      this.setState({ isLoading: false });
    });

      //OS Detection
      document.querySelectorAll('.animCTA').forEach(p => {

          p.innerText = this.state.data.button;
  });

  }




  startAnimation() {
    var stepTrack = document.querySelector('.gwlp-module--stepTrack');
    stepTrack.classList.add('gwlp-module--active');

    var listItems = document.querySelectorAll('li');
    if (listItems.length >= 1) {
      listItems[0].classList.add('gwlp-module--stepComplete');
      listItems[0].classList.remove('gwlp-module--isActive');
    }

    if (listItems.length >= 2) {
      listItems[1].classList.add('gwlp-module--isActive');
    }
    return window.triggerInstall();
  }

  render() {
    return (
      <>
        <div id={Styles.gwlp}>
          <div className={`${Styles.preloader} ${this.state.isLoading ? Styles.isLoading : ""}`}>
            <div className={Styles.loader}>
              <div className={Styles.ball}></div>
              <div className={Styles.ball}></div>
              <div className={Styles.ball}></div>
              <div className={Styles.ball}></div>
              <div className={Styles.shadow}></div>
              <div className={Styles.shadow}></div>
              <div className={Styles.shadow}></div>
              <div className={Styles.shadow}></div>
            </div>
          </div>
          <main>
            <section>
              <h1>{this.state.data.headline}</h1>
              <h2>{this.state.data.subheadline}</h2>
              <div className={Styles.compatible}>
                <img alt="checkbox" className={Styles.checkBox} src={this.state.data.checkIcon} />
                <h3>{this.state.data.compatible}</h3>
              </div>
              <img src={this.state.data.cwsbadge} className={`${Styles.cws} cl1`} onClick={this.startAnimation}></img>
              <button className="animCTA cl1" onClick={this.startAnimation}></button>

              {this.props.children}

              <div className={Styles.stepContainer}>
                <div className={Styles.stepTrack}>
                  <div className={Styles.blueLine}></div>
                </div>
                <ul>
                  <li className={Styles.isActive}>Step One</li>
                  <li>Step Two</li>
                </ul>
              </div>
            </section>
          </main>
          <footer>
            <ul>
              <li>Tightrope Interactive</li>
              <li>
                <a href="/terms-of-service/" target="_blank">Terms</a>
              </li>
              <li>
                <a href="/privacy-policy/" target="_blank">Privacy</a>
              </li>
              <li>
                <a href="/uninstall/" target="_blank">Uninstall</a>
              </li>
              <li>
                <a href="/contact-us/" target="_blank">Unsubscribe</a>
              </li>
              <li>
                <a href="/contact-us/" target="_blank">Contact</a>
              </li>
              <li>All trademarks are property of their respective owners</li>
              <li>
                <a href="/privacy-policy/#cali-consumer" target="_blank">Do Not Sell My Personal Information</a>
              </li>
            </ul>
          </footer>
        </div>
      </>
    );
  }
}

export default gwlp;
